import React, { useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal";
import VehicleInformation from "./new-order/VehicleInformation";
import PersonalInformation from "./new-order/PersonalInformation";
import BookingSummary from "./new-order/BookingSummary";
import Payment from "./new-order/Payment";
import OrderComplete from "./new-order/OrderComplete";
import axios from "axios";
import toastMessage from "../utils/toast";
import NavBarHeader from "../components/NavBarHeader";
import Marquee from "react-fast-marquee";

export default function Home(props) {
  return (
    <div className="wrapper">
      <NavBarHeader />
      <div className="content-wrapper">
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-12" style={{ "margin-top": "5%" }}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <Marquee pauseOnHover={true} autoFill={false}>
                        <pre>
                          Booking Available for state of &nbsp;
                          <span style={{ fontSize: 22, color: "orange" }}>
                            <b>Tamil Nadu, Karnataka, Andhra Pradesh</b>
                          </span>
                        </pre>
                      </Marquee>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <img
                      className="img-fluid pad"
                      src="https://hsrp.fit/img/slider-1.jpg"
                    />
                  </div>

                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div class="btn-group col-12" style={{ padding: 0 }}>
                      <button type="button" class="btn btn-warning">
                        FAQ
                      </button>
                      <button type="button" class="btn btn-warning">
                        Terms & Conditions
                      </button>
                      <button type="button" class="btn btn-warning">
                        Cancellation Policy
                      </button>
                      <button type="button" class="btn btn-warning">
                        Privacy Policy
                      </button>
                      <button type="button" class="btn btn-warning">
                        About Us
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <aside className="control-sidebar control-sidebar-dark"></aside> */}
      <footer className="main-footer">
        <strong>
          Copyright © 2023 <a href="https://hsrp.fit">hsrp.fit</a>.
        </strong>
        All rights reserved.
      </footer>
    </div>
  );
}
