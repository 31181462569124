import "./nav-link.css"
export default function NavBarHeader(props) {
  return (
    <>
      <nav className="main-header navbar navbar-expand-md navbar-light navbar-orange">
        <div className="container">
          <a href="#/" className="navbar-brand">
            <img src="img/logo_full.png" alt="YS" className="brand-image" />
          </a>
          <button
            className="navbar-toggler order-1"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="navbar-collapse order-3 collapse" id="navbarCollapse">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a href="#/book-order" className="nav-link">
                  <i className="fas fa-folder-plus"></i> Book Order
                </a>
              </li>
              <li className="nav-item">
                <a href="#/track-order" className="nav-link">
                  <i className="fas fa-search"></i> Track Order
                </a>
              </li>
              <li className="nav-item">
                <a href="#/cancel-order" className="nav-link">
                  <i className="fas fa-window-close"></i> Cancel Order
                </a>
              </li>
            </ul>
            <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
              <li className="nav-item">
                <a href="#/contactus" className="nav-link">
                  <i className="fas fa-id-badge"></i> Contact Us
                </a>
              </li>
              <li className="nav-item">
                <a href="#/help-desk" className="nav-link">
                  <i className="fas fa-headset" /> Help Desk
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
