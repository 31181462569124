import { createHashRouter, RouterProvider, Navigate } from "react-router-dom";
import BookNewOrder from "./pages/BookNewOrder";
import TrackOrder from "./pages/TrackOrder";
import ContactUs from "./pages/ContactUs";
import HelpDesk from "./pages/HelpDesk";
import CancelOrder from "./pages/CancelOrder";
import Home from "./pages/Home";
function Router() {
    const router = createHashRouter([
        {
          path: "/",
          element: <Home />
        },
        {
          path: "/book-order",
          element: <BookNewOrder />
        },
        {
          path: "/track-order",
          element: <TrackOrder />
        },
        {
          path: "/cancel-order",
          element: <CancelOrder />
        },
        {
          path: "/contactus",
          element: <ContactUs />
        },
        {
          path: "/help-desk",
          element: <HelpDesk />
        },
      ]);

  return (
    <div>
        <RouterProvider router={router} />
    </div>
  );
}

export default Router;
