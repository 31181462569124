import React, { useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal";
import VehicleInformation from "./new-order/VehicleInformation";
import PersonalInformation from "./new-order/PersonalInformation";
import BookingSummary from "./new-order/BookingSummary";
import Payment from "./new-order/Payment";
import OrderComplete from "./new-order/OrderComplete";
import axios from "axios";
import toastMessage from "../utils/toast";
import NavBarHeader from "../components/NavBarHeader";

export default function BookNewOrder(props) {
  const steps = [
    { title: "Vehicle Details" },
    { title: "Personal Details" },
    { title: "Booking Summary" },
    { title: "Payment" },
    { title: "Completed" },
  ];
  const [page, setPage] = useState(0);
  const [vehicleLoading, setVehicleLoading] = useState(false);
  const [regnNo, setRegnNO] = useState("");
  const [chassisNo, setChassisNo] = useState("");
  const [engineNo, setEngineNo] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [emailId, setEmailId] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");

  const nextPage = () => {
    setPage(page + 1);
  };

  const validateVahan = async () => {
    setVehicleLoading(true);
    const payload = {
      regnNO: regnNo, //"TN18BM1036",
      chasisNo: chassisNo, //"03724",
      engineNo: engineNo, //"A3724",
      userid: "yarya",
    };
    console.log(payload);
    await axios
      .post(`https://api-portal.hsrp.fit/vahan/getVehicleStatus`, payload, {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((response) => {
        const message = response.data;
        if (message.match(/^.*you are not authorized vendor.*$/)) {
          console.log(message);
          toastMessage(
            "error",
            "As a Vendor we are not authorised for this vehicle"
          );
          alert("As a Vendor we are not authorised for this vehicle");
        } else if (message.match(/^.*you are authorized vendor.*$/)) {
          toastMessage(
            "success",
            "As a Vendor we are authorised for this vehicle..."
          );
          setVehicleLoading(false);
          nextPage();
        } else {
          alert(response.data);
          setVehicleLoading(false);
        }
      })
      .catch((error) => {
        toastMessage("error", "Something went wrong, Please try again later");
      });
  };

  const previousPage = () => {
    setPage(page - 1);
  };
  return (
    <div className="wrapper">
      <NavBarHeader />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        <div className="content">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-12 col-lg-8">
                <Stepper steps={steps} activeStep={page} />
                <hr />
                {page === 0 && (
                  <VehicleInformation
                    message={"Vehicle Details"}
                    nextpage={validateVahan}
                    previousPage={previousPage}
                    isLoading={vehicleLoading}
                    setRegnNO={setRegnNO}
                    setChassisNo={setChassisNo}
                    setEngineNo={setEngineNo}
                    regnNo={regnNo}
                    chasisNo={chassisNo}
                    engineNo={engineNo}
                  />
                )}
                {page === 1 && (
                  <PersonalInformation
                    message={"Personal Information"}
                    nextpage={nextPage}
                    previousPage={previousPage}
                    firstName={firstName}
                    lastName={lastName}
                    mobileNo={mobileNo}
                    emailId={emailId}
                    address={address}
                    location={location}
                    setFirstName={setFirstName}
                    setLastName={setLastName}
                    setMobileNo={setMobileNo}
                    setEmailId={setEmailId}
                    setAddress={setAddress}
                    setLocation={setLocation}
                  />
                )}
                {page === 2 && (
                  <BookingSummary
                    message={"Booking Summary"}
                    nextpage={nextPage}
                    previousPage={previousPage}
                    regnNo={regnNo}
                    chassisNo={chassisNo}
                    engineNo={engineNo}
                    firstName={firstName}
                    lastName={lastName}
                    mobileNo={mobileNo}
                    emailId={emailId}
                    address={address}
                    location={location}
                  />
                )}
                {page === 3 && (
                  <Payment
                    message={"Payment"}
                    nextpage={nextPage}
                    previousPage={previousPage}
                  />
                )}
                {page === 4 && (
                  <OrderComplete
                    message={"Order Completed"}
                    nextpage={nextPage}
                    previousPage={previousPage}
                  />
                )}
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
      {/* Control Sidebar */}
      <aside className="control-sidebar control-sidebar-dark">
        {/* Control sidebar content goes here */}
      </aside>
      {/* /.control-sidebar */}
      {/* Main Footer */}
      <footer className="main-footer">
        {/* To the right */}
        {/* <div className="float-right d-none d-sm-inline">Anything you want</div> */}
        {/* Default to the left */}
        <strong>
          Copyright © 2023 <a href="https://hsrp.fit">hsrp.fit</a>.
        </strong>
        All rights reserved.
      </footer>
    </div>
  );
}
