import React, { Component } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const toastMessage = (level, message) => {
  toastr.options = {
    progressBar: true,
  };
  switch (level) {
    case "success":
      return toastr.success(message);
    case "info":
      return toastr.info(message);
    case "error":
      return toastr.error(message);
    case "warning":
      return toastr.warning(message);
    default:
      return [];
  }
};

export default toastMessage;