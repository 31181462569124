import React, { useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal";
import VehicleInformation from "./new-order/VehicleInformation";
import PersonalInformation from "./new-order/PersonalInformation";
import BookingSummary from "./new-order/BookingSummary";
import Payment from "./new-order/Payment";
import OrderComplete from "./new-order/OrderComplete";
import axios from "axios";
import toastMessage from "../utils/toast";
import NavBarHeader from "../components/NavBarHeader";

export default function TrackOrder(props) {

  return (
    <div className="wrapper">
      <NavBarHeader />
      <div className="content-wrapper">
        <div className="content">
          <div className="container">
            <div className="row d-flex justify-content-center">
            <div className="col-md-12 col-lg-4" style={{"margin-top":"5%"}}>
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title"> <i class="fas fa-search"></i> Track Your Order</h3>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Order Number</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Order No"
                            />
                          </div>
                          <div className="form-group">
                            <label>Vehicle Registration No</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Vechicle Registration No"
                            />
                          </div>
                          <div className="form-group">
                            <label>Captcha</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Captcha"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="card-footer">
                    <button
                      type="button"
                      className="btn btn-primary float-right"
                      // onClick={nextpage}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside className="control-sidebar control-sidebar-dark"></aside>
      <footer className="main-footer">
        <strong>
          Copyright © 2023 <a href="https://hsrp.fit">hsrp.fit</a>.
        </strong>
        All rights reserved.
      </footer>
    </div>
  );
}
