export default function BookingSummary(props) {
  const {
    message,
    nextpage,
    previousPage,
    regnNo,
    chassisNo,
    engineNo,
    firstName,
    lastName,
    mobileNo,
    emailId,
    address,
    location
  } = props;
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">{message}</h3>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <h3 className="text-primary text-center">
                <i className="fas fa-car" /> Vehicle Details
              </h3>

              <br />
              <div className="text-muted">
                <p className="text-sm">
                  Registration No
                  <b className="d-block">{regnNo}</b>
                </p>
                <p className="text-sm">
                  Chassis No
                  <b className="d-block">{chassisNo}</b>
                </p>
                <p className="text-sm">
                  Engine No
                  <b className="d-block">{engineNo}</b>
                </p>
              </div>
            </div>
            <div className="col-6">
              <h3 className="text-primary text-center">
                <i className="fas fa-user" /> Contact Details
              </h3>

              <br />
              <div className="text-muted">
                <p className="text-sm">
                  FirstName
                  <b className="d-block">{firstName}</b>
                </p>
                <p className="text-sm">
                  LastName
                  <b className="d-block">{lastName}</b>
                </p>
                <p className="text-sm">
                  Mobile
                  <b className="d-block">{mobileNo}</b>
                </p>
                <p className="text-sm">
                  Email
                  <b className="d-block">{emailId}</b>
                </p>
                <p className="text-sm">
                  Address
                  <b className="d-block">{address}</b>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={previousPage}
          >
            Previous
          </button>
          <button
            type="button"
            className="btn btn-primary float-right"
            onClick={nextpage}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
