export default function PersonalInformation(props) {
  const {
    message,
    nextpage,
    previousPage,
    firstName,
    lastName,
    mobileNo,
    emailId,
    address,
    location,
    setFirstName,
    setLastName,
    setMobileNo,
    setEmailId,
    setAddress,
    setLocation,
  } = props;
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">{message}</h3>
        </div>

        <div className="card-body">
          <form>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter First Name"
                    defaultValue={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Last Name"
                    defaultValue={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Mobile No</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Mobile No"
                    defaultValue={mobileNo}
                    onChange={(e) => setMobileNo(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email Id"
                    defaultValue={emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Address</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter Current Address"
                    defaultValue={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Location</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Preferable Location"
                    defaultValue={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="card-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={previousPage}
          >
            Previous
          </button>
          <button
            type="button"
            className="btn btn-primary float-right"
            onClick={nextpage}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
