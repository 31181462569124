import React, { useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal";
import VehicleInformation from "./new-order/VehicleInformation";
import PersonalInformation from "./new-order/PersonalInformation";
import BookingSummary from "./new-order/BookingSummary";
import Payment from "./new-order/Payment";
import OrderComplete from "./new-order/OrderComplete";
import axios from "axios";
import toastMessage from "../utils/toast";
import NavBarHeader from "../components/NavBarHeader";

export default function HelpDesk(props) {
  return (
    <div className="wrapper">
      <NavBarHeader />
      <div className="content-wrapper">
        <div className="content">
          <div className="container">
            <div className="row d-flex justify-content-center">
            <div className="col-md-12 col-lg-6" style={{ "margin-top": "5%" }}>
                <div className="card">
                  <div className="card-body row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="inputName">Name</label>
                        <input
                          type="text"
                          id="inputName"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputEmail">Mobile No</label>
                        <input
                          type="text"
                          id="inputEmail"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputEmail">E-Mail</label>
                        <input
                          type="email"
                          id="inputEmail"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputSubject">Subject</label>
                        <input
                          type="text"
                          id="inputSubject"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputMessage">Message</label>
                        <textarea
                          id="inputMessage"
                          className="form-control"
                          rows={4}
                          defaultValue={""}
                        />
                      </div>
                      <div className="form-group float-right">
                        <input
                          type="submit"
                          className="btn btn-primary"
                          defaultValue="Send message"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside className="control-sidebar control-sidebar-dark"></aside>
      <footer className="main-footer">
        <strong>
          Copyright © 2023 <a href="https://hsrp.fit">hsrp.fit</a>.
        </strong>
        All rights reserved.
      </footer>
    </div>
  );
}
