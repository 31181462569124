export default function Payment(props) {
  const { message, nextpage, previousPage } = props;
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">{message}</h3>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <p className="lead">Order Price</p>
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th style={{ width: "50%" }}>Subtotal:</th>
                      <td>&#x20b9; 250.30</td>
                    </tr>
                    <tr>
                      <th>Tax (18%)</th>
                      <td>&#x20b9; 10.34</td>
                    </tr>
                    <tr>
                      <th>Shipping:</th>
                      <td>&#x20b9; 5.80</td>
                    </tr>
                    <tr>
                      <th>Total:</th>
                      <td>&#x20b9; 265.24</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={previousPage}
          >
            Previous
          </button>
          <button
            type="button"
            className="btn btn-primary float-right"
            onClick={nextpage}
          >
            Pay
          </button>
        </div>
      </div>
    </>
  );
}
