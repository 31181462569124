export default function VehicleInformation(props) {
  const { message, nextpage, previousPage, isLoading, setRegnNO, setChassisNo, setEngineNo, regnNo, chasisNo, engineNo } = props;
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">{message}</h3>
        </div>
        {isLoading ? (
          <div className="overlay">
            <i className="fas fa-2x fa-sync fa-spin" />
          </div>
        ) : (
          <>
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Registration No</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Vechicle Registration No"
                        defaultValue={regnNo}
                        onChange={(e) => setRegnNO(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Chassis No</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Vechicle Chassis No"
                        defaultValue={chasisNo}
                        onChange={(e) => setChassisNo(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Engine No</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Vechicle Engine No"
                        defaultValue={engineNo}
                        onChange={(e) => setEngineNo(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Captcha</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter ..."
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="card-footer">
              <button
                type="button"
                className="btn btn-primary float-right"
                onClick={nextpage}
              >
                Next
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
